/* eslint-disable @typescript-eslint/dot-notation */
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import BasicButton from 'components/Button/BasicButton';
import checklistSearch from '../../../assets/icons/input-search.svg';
import activityNameIcon from '../../../assets/icons/checklist.svg';
import activityDescriptionIcon from '../../../assets/icons/description.svg';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import LabelledInputWithImage from 'components/Input/LabelledInputWithImage/LabelledInputWithImage';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import Modal from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import './EditDailyAgenda.scss';
import axios from 'axios';
import { selectSelectedBusinessUnit } from 'store/selectors/globalFilters.selectors';
import { useSelector } from 'react-redux';
import { useGetAllPillarLazyQuery } from 'generated/graphql';

type ActivityType = {
  id: string;
  label: string;
  description: string;
  pillar: any;
};

enum DndCards {
  CARD_1 = 'CARD_1',
  CARD_2 = 'CARD_2',
}

enum Frequency {
  DAILY = 'DAILY',
  DAILY_ACCUMULATIVE = 'DAILY_ACCUMULATIVE',
  WEEKELY = 'WEEKELY',
  WEEKELY_ACCUMULATIVE = 'WEEKELY_ACCUMULATIVE',
  BIWEEKELY = 'BIWEEKELY',
  BIWEEKELY_ACCUMULATIVE = 'BIWEEKELY_ACCUMULATIVE',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

const EditDailyAgenda = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit);

  const [getPillars, { data: getPillarsRes }] = useGetAllPillarLazyQuery();
  const pillarsData = getPillarsRes?.getAllPillar;

  const frequencyList = [
    { label: t(TRANSLATIONS.DAILY), slug: Frequency.DAILY },
    { label: `${t(TRANSLATIONS.DAILY)} ${t(TRANSLATIONS.ACCUMULATIVE)}  `, slug: Frequency.DAILY_ACCUMULATIVE },
    { label: t(TRANSLATIONS.WEEKELY), slug: Frequency.WEEKELY },
    { label: `${t(TRANSLATIONS.WEEKELY)} ${t(TRANSLATIONS.ACCUMULATIVE)}  `, slug: Frequency.WEEKELY_ACCUMULATIVE },
    { label: t(TRANSLATIONS.BIWEEKELY), slug: Frequency.BIWEEKELY },
    { label: `${t(TRANSLATIONS.BIWEEKELY)} ${t(TRANSLATIONS.ACCUMULATIVE)}  `, slug: Frequency.BIWEEKELY_ACCUMULATIVE },
    { label: t(TRANSLATIONS.MONTHLY), slug: Frequency.MONTHLY },
    { label: t(TRANSLATIONS.QUARTERLY), slug: Frequency.QUARTERLY },
  ];

  const inputRef = useRef<HTMLInputElement>(null);

  const [dndChecklistList, setDndChecklistList] = useState<any>({
    [DndCards.CARD_1]: [],
    [DndCards.CARD_2]: [],
  });
  const [search, setSearch] = useState<string>('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState<string | null>(null);
  const [openPositionsDropdown, setOpenPositionsDropdown] = useState<boolean>(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState<boolean>(false);
  const [isEditingActivity, setIsEditingActivity] = useState<boolean>(false);
  const [newActivities, setNewActivities] = useState<any>([]);
  const [newActivityObject, setNewActivityObject] = useState<ActivityType>({ id: '', label: '', description: '', pillar: { name: '', id: null } });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPosition, setSelectedPosition] = useState<any>(null);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [positions, setPositions] = useState<any>([]);
  const [originalPositions, setOriginalPositions] = useState<any>([]);


  const searchChecklists = useCallback(() => {
    const tempActivities = newActivities?.filter((activity: any) => activity
      ?.label?.toLowerCase().includes(search?.toLocaleLowerCase()));
    if (tempActivities.length > 0) {
      const list = JSON.parse(JSON.stringify(dndChecklistList));
      list[DndCards.CARD_1] = tempActivities;
      setDndChecklistList(list);
    } else {
      const list = {
        [DndCards.CARD_1]: [],
        [DndCards.CARD_2]: [...dndChecklistList[DndCards.CARD_2]],
      };
      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (search) {
      searchChecklists();
    } else {
      const list = {
        [DndCards.CARD_1]: newActivities,
        [DndCards.CARD_2]: [...dndChecklistList[DndCards.CARD_2]],
      };
      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchChecklists]);

  useEffect(() => {
    const fetchOperatorPositions = async () => {
      try {
        const url = `/api/v1/da-get-operator-positions-by-bu?buId=${selectedBusinessUnit.id}`;
        const res = await axios.get(url);
        setPositions(res.data);
        setOriginalPositions(res.data);
      } catch (error) {
        console.error('Error fetching operator positions:', error);
      }
    };
    getPillars({ variables: { pillarsToIgnore: [] } });

    fetchOperatorPositions();
  }, [getPillars, selectedBusinessUnit]);

  const memoizedPositions = useMemo(() => positions, [positions]);

  const onPressSaveChanges = () => { };

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    if (!destination) return;

    const sourceColumn = source?.droppableId?.split('-')[0] as DndCards;
    const sourcePosition = source?.droppableId?.split('-')[1];
    const destinationColumn = destination?.droppableId?.split('-')[0] as DndCards;
    const destinationPosition = destination?.droppableId?.split('-')[1];

    const itemsWithoutSourcePosition = Array.from(dndChecklistList?.[sourceColumn])?.filter((i: any) => i?.position !== sourcePosition);
    const destinationPositionItems = Array.from(dndChecklistList?.[sourceColumn])?.filter((i: any) => i?.position === destinationPosition);
    const itemsWithoutSourceAndDestinationPositions = Array.from(dndChecklistList?.[sourceColumn])?.filter((i: any) => (i?.position !== destinationPosition && i?.position !== sourcePosition));

    if ((sourceColumn === DndCards.CARD_2) && (sourcePosition === destinationPosition)) {
      const items = Array.from(dndChecklistList?.[sourceColumn])?.filter((i: any) => i?.position === sourcePosition);
      const [removed] = items?.splice(source?.index, 1);
      items.splice(destination?.index, 0, removed);
      setDndChecklistList((prevState: any) => ({
        ...prevState,
        [sourceColumn]: [...items, ...itemsWithoutSourcePosition],
      }));
    } else if ((sourceColumn === DndCards.CARD_2 && destinationColumn === DndCards.CARD_2) && (sourcePosition !== destinationPosition)) {
      const items = Array.from(dndChecklistList?.[sourceColumn])?.filter((i: any) => i?.position === sourcePosition);
      const [removed]: any = items?.splice(source?.index, 1);
      if (removed?.position) {
        removed.position = destinationPosition;
      }
      destinationPositionItems.splice(destination?.index, 0, removed);
      setDndChecklistList((prevState: any) => ({
        ...prevState,
        [sourceColumn]: [...items, ...destinationPositionItems, ...itemsWithoutSourceAndDestinationPositions],
      }));
    } else if (sourceColumn === destinationColumn) {
      const items = Array.from(dndChecklistList?.[sourceColumn]);
      const [removed] = items?.splice(source?.index, 1);
      items.splice(destination?.index, 0, removed);
      if (sourceColumn === DndCards.CARD_1) {
        setNewActivities(items);
      }
      setDndChecklistList((prevState: any) => ({
        ...prevState,
        [sourceColumn]: items,
      }));
    } else {
      if (destinationColumn === DndCards.CARD_2) {
        const movingCard = dndChecklistList?.[sourceColumn]?.find(
          (_: any, index: number) => index === source?.index,
        );
        setDndChecklistList((preState: any) => {

          const destinationPositionsItems = dndChecklistList?.[destinationColumn]?.filter((i: any) => i?.position === destinationPosition);
          const itemsWithoutDestinationPosition = Array.from(dndChecklistList?.[destinationColumn])?.filter((i: any) => i?.position !== destinationPosition);
          let updatedCard1Items = [];

          const updatedItems = [
            ...destinationPositionsItems?.slice?.(0, destination?.index),
            { ...movingCard, position: destinationPosition, frequency: frequencyList[0] },
            ...destinationPositionsItems?.slice?.(destination?.index),
          ];

          if (search?.length) {
            updatedCard1Items = newActivities?.filter((_: any, index: number) => index !== source?.index);
          }
          setNewActivities(updatedCard1Items?.length ? updatedCard1Items : dndChecklistList?.[sourceColumn]?.filter?.((_: any, index: number) => index !== source?.index));
          return ({
            ...preState,
            [sourceColumn]: preState?.[sourceColumn]?.filter?.(
              (_: any, index: number) => index !== source?.index,
            ),
            [destinationColumn]: [...updatedItems, ...itemsWithoutDestinationPosition],
          });
        });
      } else {
        const movingCard = dndChecklistList?.[sourceColumn]?.filter((i: any) => i?.position === sourcePosition)?.find(
          (_: any, index: number) => index === source?.index,
        );
        let updatedCard1Items = [];
        const sourcePositionsItems = dndChecklistList?.[sourceColumn]?.filter((i: any) => i?.position === sourcePosition);
        const itemsWithoutSourcePositionV1 = Array.from(dndChecklistList?.[sourceColumn])?.filter((i: any) => i?.position !== sourcePosition);
        const updatedItems = [
          ...sourcePositionsItems?.slice?.(0, source?.index),
          ...sourcePositionsItems?.slice?.(source?.index + 1),
        ];
        if (search?.length) {
          updatedCard1Items = [
            ...newActivities?.slice?.(0, destination?.index),
            { ...movingCard },
            ...newActivities?.slice?.(destination?.index),
          ];
        }
        setNewActivities(updatedCard1Items?.length ? updatedCard1Items : [
          ...dndChecklistList?.[destinationColumn]?.slice?.(0, destination?.index),
          { ...movingCard },
          ...dndChecklistList?.[destinationColumn]?.slice?.(destination?.index),
        ]);
        setDndChecklistList((preState: any) => {
          return ({
            ...preState,
            [sourceColumn]: [...updatedItems, ...itemsWithoutSourcePositionV1],
            [destinationColumn]: [
              ...preState?.[destinationColumn]?.slice?.(0, destination?.index),
              { ...movingCard },
              ...preState?.[destinationColumn]?.slice?.(destination?.index),
            ],
          });
        });
      }
    }
  }

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
  };

  const handleActivityNameChange = async (event: any) => {
    setNewActivityObject({
      ...newActivityObject,
      label: event.target.value,
    });
  };

  const handleActivityDescriptionChange = async (event: any) => {
    setNewActivityObject({
      ...newActivityObject,
      description: event.target.value,
    });
  };

  const onAddActivity = () => {
    setShowAddActivityModal(true);
  };

  const onDeletePosition = (positionD: string) => {
    const deletedPosition = originalPositions?.find((item: any) => item.position === positionD);
    const clonedPositions = JSON.parse(JSON.stringify(positions));

    const clonedSelectedPositions = JSON.parse(JSON.stringify(selectedPositions));
    const filteredSelectedPosition = clonedSelectedPositions?.filter((i: any) => i !== positionD);
    clonedPositions?.push(deletedPosition);

    const clonedDndItems = JSON.parse(JSON.stringify(dndChecklistList));
    const filteredDndItems = clonedDndItems?.[DndCards.CARD_2]?.filter((i: any) => i?.position !== positionD);
    setPositions(clonedPositions);

    setSelectedPositions(filteredSelectedPosition);
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_2]: filteredDndItems,
    });
  };

  const handleFrequencyChange = (data: any, selectedItemIndex: number, position: string) => {
    const selectedFrequency = frequencyList.find(item => item.slug === data?.key) || frequencyList[0];
    const selectedCard = dndChecklistList?.[DndCards.CARD_2]?.filter((i: any) => i?.position === position)?.find(
      (_: any, index: number) => index === selectedItemIndex,
    );
    const destinationPositionsItems = dndChecklistList?.[DndCards.CARD_2]?.filter((i: any) => i?.position === position);
    const itemsWithoutDestinationPosition = Array.from(dndChecklistList?.[DndCards.CARD_2])?.filter((i: any) => i?.position !== position);

    const updatedItems = [
      ...destinationPositionsItems?.slice?.(0, selectedItemIndex),
      { ...selectedCard, frequency: selectedFrequency },
      ...destinationPositionsItems?.slice?.(selectedItemIndex + 1),
    ];

    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_2]: [...updatedItems, ...itemsWithoutDestinationPosition],
    });
    setOpenDropdownIndex(null);
  };

  const handleAddPosition = ({ key }: { key: any }) => {
    const filteredPosition = positions?.find((item: any) => item.position === key) || memoizedPositions[0];
    const clonedPositions = JSON.parse(JSON.stringify(selectedPositions));
    clonedPositions?.push(filteredPosition?.position);
    setSelectedPositions(clonedPositions);
    setPositions(positions?.filter((item: any) => item.position !== key));
    setOpenPositionsDropdown(false);
  };

  const onDeleteActivity = (activityId: string, activityPosition: string) => {
    const clonedDndItems = JSON.parse(JSON.stringify(dndChecklistList));
    const filteredDndItems = clonedDndItems?.[DndCards.CARD_2]?.filter((i: any) => i?.position === activityPosition)?.filter((i: any) => i?.id !== activityId);
    const itemsWithoutActivityPosition = Array.from(dndChecklistList?.[DndCards.CARD_2])?.filter((i: any) => i?.position !== activityPosition);
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_2]: [...filteredDndItems, ...itemsWithoutActivityPosition],
    });
  };

  const onChangePillar = async (e: any) => {
    const selectedPillar = pillarsData?.find((pillar: any) => pillar?.name === e?.label);
    setNewActivityObject({
      ...newActivityObject,
      pillar: selectedPillar,
    });
  };

  const onToggleNewActivityModal = () => {
    setShowAddActivityModal(false);
    setNewActivityObject({
      id: '',
      label: '',
      description: '',
      pillar: {
        name: '',
        id: null,
      },
    });
  };

  function generateRandomId(prefix = 'item') {
    const randomPart = Math.random().toString(36).substring(2, 8);
    const timestamp = Date.now().toString(36);
    return `${prefix}-${timestamp}-${randomPart}`;
  }

  const onCreateNewActivity = () => {
    const newActivity = {
      ...newActivityObject,
      id: generateRandomId(),
    };
    const cardItems = JSON.parse(JSON.stringify(dndChecklistList[DndCards.CARD_1]));
    cardItems?.push(newActivity);
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_1]: cardItems,
    });
    setNewActivities(cardItems);
    onToggleNewActivityModal();
  };

  // const onEditActivity = (selectedActivity: ActivityType) => {
  //   const cardItems = JSON.parse(JSON.stringify(dndChecklistList[DndCards.CARD_1]));
  //   const filteredActivity = cardItems?.find((c: any) => c?.id === selectedActivity?.id);
  //   setNewActivityObject(filteredActivity);
  //   setShowAddActivityModal(true);
  //   setIsEditingActivity(true);
  // };

  const onSaveEditedActivity = () => {
    const cardItems = JSON.parse(JSON.stringify(dndChecklistList[DndCards.CARD_1]));
    const updatedItems = cardItems?.map((c: any) => {
      if (c?.id === newActivityObject?.id) {
        return newActivityObject;
      }
      return c;
    });
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_1]: updatedItems,
    });
    setIsEditingActivity(false);
    onToggleNewActivityModal();
  };

  const isNewActivityCreateButtonDisabled = useMemo(() => {
    let flag = false;
    if (!newActivityObject?.label.length || !newActivityObject?.description?.length || !newActivityObject?.pillar?.name?.length) {
      flag = true;
    }
    return flag;
  }, [newActivityObject]);

  const onPressBack = () => {
    navigate('/viewDailyAgenda', { replace: true });
  };

  return (
    <div className="editDailyAgenda">
      {showAddActivityModal && (
        <Modal
          modalContainerStyles={{
            width: '50vw',
            left: '26%',
            maxHeight: '90vh',
            minHeight: '20vh',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '40%',
            transform: 'translateY(-50%)',
          }}
        >
          <div className="editDailyAgenda__addActivityModal">
            <p className="editDailyAgenda__addActivityModal__label">{t(TRANSLATIONS.NEW_ACTIVITY)}</p>
            <InputWithLabel
              inputImg={activityNameIcon}
              onChange={handleActivityNameChange}
              placeholder={t(TRANSLATIONS.WRITE_ACTIVITY_NAME)}
              value={newActivityObject.label}
              labelClassName="search-header"
              type="text"
              maxLength={1000}
              inputClassName="search-input"
              containerClassName="editDailyAgenda__addActivityModal__name"
            />
            <InputWithLabel
              inputImg={activityDescriptionIcon}
              onChange={handleActivityDescriptionChange}
              placeholder={t(TRANSLATIONS.WRITE_DESCRIPTION)}
              value={newActivityObject.description}
              labelClassName="search-header"
              type="text"
              maxLength={1000}
              inputClassName="search-input"
              containerClassName="editDailyAgenda__addActivityModal__description"
            />
            <div className="editDailyAgenda__addActivityModal__pillarContainer">
              <LabelledInputWithImage
                autoCompleteIcon={ImageLinks.listHorizontal}
                inputStyle={{ width: '100vw', borderColor: '#DEDEDE', padding: '4px', fontWeight: 300, borderRadius: '5px', paddingLeft: '45px' }}
                onChange={e => onChangePillar(e)}
                placeholder={t(TRANSLATIONS.SELECT_PILLAR)}
                dropdownOptions={(pillarsData as any || []).map(pillar => ({
                  value: pillar.id,
                  label: pillar.name,
                }))}
                type="autocomplete-dropdown"
                labelStyle={{ width: '220px' }}
                borderColor={'#DEDEDE'}
                borderHoverColor={'#4472C4'}
                selectedValue={newActivityObject.pillar}
              />
            </div>
            <p className="editDailyAgenda__addActivityModal__footerDescription">{t(TRANSLATIONS.THIS_ACTIVITY_WILL_ONLY_DISPLAY_ONE_QUESTION_IN_THE_MOBILE_APP_WHICH_WILL_BE_TO_REQUEST_PHOTOGRAPHIC_EVIDENCE)}</p>
            <div className="editDailyAgenda__addActivityModal__buttonContainer">
              <BasicButton
                text={t(TRANSLATIONS.CANCEL)}
                className="editDailyAgenda__addActivityModal__buttonContainer__cancelButton"
                onClick={onToggleNewActivityModal} />
              <BasicButton
                disabled={isNewActivityCreateButtonDisabled}
                text={isEditingActivity ? t(TRANSLATIONS.SAVE_CHANGES) : t(TRANSLATIONS.CREATE)}
                className={`${isNewActivityCreateButtonDisabled ? 'editDailyAgenda__addActivityModal__buttonContainer__createButtonWithoutHover' : 'editDailyAgenda__addActivityModal__buttonContainer__createButton'}`}
                onClick={isEditingActivity ? onSaveEditedActivity : onCreateNewActivity} />
            </div>
          </div>
        </Modal>
      )}
      <div className="editDailyAgenda__header">
        <div className="editDailyAgenda__header__info">
          <div role='button' onClick={onPressBack} className="editDailyAgenda__header__info__goBack">
            <img
              src={ImageLinks.arrowLeftV1}
              className="editDailyAgenda__header__info__goBack__icon"
              alt="back"
            />
          </div>
          <div className="editDailyAgenda__header__info__content">
            <p className="editDailyAgenda__header__info__content__title">{t(TRANSLATIONS.EDITING_AGENDA_PROGRAMMING)}</p>
            <p className="editDailyAgenda__header__info__content__subtitle">{`${t(TRANSLATIONS.PROGRAMMING_OF)} ${selectedBusinessUnit.name || ''} ${t(TRANSLATIONS.TO_EDIT_ANOTHER_ONE_GO_BACK_AND_SELECT_A_DIFFERENT_BU)}`}</p>
          </div>
        </div>
        <BasicButton
          text={t(TRANSLATIONS.SAVE_CHANGES)}
          className="editDailyAgenda__header__edit_button"
          onClick={onPressSaveChanges}
        />
      </div>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="editDailyAgenda__dnd">
            {Object.keys(dndChecklistList)?.map((column: any) => {
              const isLeftPanel = column === DndCards.CARD_1;

              if (isLeftPanel) {
                return (
                  <div className="editDailyAgenda__dnd__dndCard1">
                    <div className="editDailyAgenda__dnd__dndCard1__cards">
                      <div className="editDailyAgenda__dnd__dndCard1__cards__searchWrap">
                        <InputWithLabel
                          inputRef={inputRef}
                          inputImg={checklistSearch}
                          onChange={handleSearch}
                          placeholder={t(TRANSLATIONS.SEARCH)}
                          value={search}
                          labelClassName="search-header"
                          type="text"
                          maxLength={1000}
                          inputClassName="search-input"
                          containerClassName="editDailyAgenda__dnd__dndCard1__cards__searchWrap__search"
                        />
                        <div role='button' onClick={onAddActivity} className="editDailyAgenda__dnd__dndCard1__cards__searchWrap__newTaskLabel">+ {t(TRANSLATIONS.NEW)}</div>
                      </div>
                      <Droppable droppableId={column}>
                        {provided => (
                          <div ref={provided.innerRef} className="editDailyAgenda__dnd__dndCard1__cards__wrap">
                            {(dndChecklistList?.[column]?.length) ? (
                              <div className="editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer">
                                {dndChecklistList?.[column]?.map(
                                  (cardData: any, index1: number) => {
                                    return (
                                      <Draggable
                                        key={cardData?.id}
                                        draggableId={cardData?.id}
                                        index={index1}
                                      >
                                        {providedV1 => (
                                          <div
                                            className="editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer__card"
                                            role="presentation"
                                            ref={providedV1.innerRef}
                                            {...providedV1.draggableProps}
                                            {...providedV1.dragHandleProps}
                                          >
                                            <p className='editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer__card__title'>
                                              {cardData?.label}
                                            </p>
                                            {/* <div role="button" onClick={() => onEditActivity(cardData)} className="editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer__card__editIcon">
                                              <img
                                                src={ImageLinks.editGrey}
                                                alt="deletePosition"
                                              />
                                            </div> */}
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                              </div>
                            ) : (
                              <div className="editDailyAgenda__dnd__dndCard1__cards__wrap__noDataWrap">
                                <p className="editDailyAgenda__dnd__dndCard1__cards__wrap__noDataWrap__title">{t(TRANSLATIONS.THRE_ARE_NO_TASKS_CREATED_YET_TO_CREATE_A_NEW_ONE_PRESS_NEW)}</p>
                                <p className="editDailyAgenda__dnd__dndCard1__cards__wrap__noDataWrap__subTitle">{t(TRANSLATIONS.THE_TASKS_OF_THE_DPO_MODULES_ARE_ALREADY_CONNECTD_TO_THE_DAILY_AGENDA_THEY_DO_NOT_NEED_TO_BE_CREATED)}</p>
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                );
              }

              return (
                <div className="editDailyAgenda__dnd__dndCard2">
                  <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap">
                    <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap">
                      <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap__container">
                        <ChartDropdown
                          onChange={handleAddPosition}
                          selectedDropDownItem={selectedPosition}
                          dropDownItems={positions}
                          isDisabled={positions?.length === 0}
                          dropDownOpen={openPositionsDropdown}
                          setDropDownOpen={setOpenPositionsDropdown}
                          customLabel={`+ ${t(TRANSLATIONS.ADD_POSITION)}`}
                          customLabelClassName={positions?.length ? 'editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap__container__label' : 'editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap__container__disabledLabel'}
                        />
                      </div>
                    </div>
                    {selectedPositions?.length ? (
                      selectedPositions.map((position, index2) => (
                        <div key={index2} className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards">
                          <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header">
                            <p className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header__label">{position}</p>
                            <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header__deletePostionWrap">
                              <img src={ImageLinks.deleteGreyV1} alt="delete position" />
                              <div role='button' onClick={() => onDeletePosition(position)} className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header__deletePostionWrap__label">{t(TRANSLATIONS.DELETE_POSITION)}</div>
                            </div>
                          </div>
                          <Droppable droppableId={`${column}-${position}`}>
                            {provided => (
                              <div ref={provided.innerRef} className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap">
                                {(dndChecklistList[DndCards.CARD_2]?.filter((item: any) => item?.position === position)?.length) ? (
                                  <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer">
                                    {dndChecklistList[DndCards.CARD_2]?.filter((item: any) => item?.position === position)?.map(
                                      (cardData: any, index1: number) => {
                                        return (
                                          <Draggable
                                            key={cardData?.id}
                                            draggableId={cardData?.id}
                                            index={index1}
                                          >
                                            {providedV1 => (
                                              <div
                                                className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card"
                                                role="presentation"
                                                ref={providedV1.innerRef}
                                                {...providedV1.draggableProps}
                                                {...providedV1.dragHandleProps}
                                              >
                                                <p className='editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card__title'>
                                                  {cardData?.label}
                                                </p>
                                                <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card__positionWrap">
                                                  <ChartDropdown
                                                    onChange={(data: any) => handleFrequencyChange(data, index1, position)}
                                                    selectedDropDownItem={cardData?.frequency}
                                                    dropDownItems={frequencyList}
                                                    dropDownOpen={openDropdownIndex === `${index1}-${position}`}
                                                    setDropDownOpen={() => setOpenDropdownIndex(openDropdownIndex === `${index1}-${position}` ? null : `${index1}-${position}`)}
                                                    labelClassName="label-default"
                                                  />
                                                  <div role="button" onClick={() => onDeleteActivity(cardData?.id, position)} className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card__positionWrap__deleteIcon">
                                                    <img
                                                      src={ImageLinks.deleteGreyV2}
                                                      alt="deletePosition"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                  </div>
                                ) : (
                                  <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__noDataWrap">
                                    <p className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__noDataWrap__title">{t(TRANSLATIONS.DRAG_A_CREATED_TASK_TO_START)}</p>
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      ),
                      )
                    ) : (
                      <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__noPositionsAddedWrap">
                        <p className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__noPositionsAddedWrap__label">{t(TRANSLATIONS.THERE_ARE_NO_POSITIONS_CREATED_YET_TO_ADD_A_NEW_ONE_PRESS_ADD_POSITION)}</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default EditDailyAgenda;
