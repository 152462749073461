/* eslint-disable @typescript-eslint/default-param-last */
import { fromJS } from 'immutable';
import {
  SET_CAN_ACCESS_SCHEDULE_SCREEN,
} from '../actions/dailyAgenda.actions';

const INITIAL_STATE = fromJS({
  canAccessScheduleScreen: false,
});

const dailyAgendaReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_CAN_ACCESS_SCHEDULE_SCREEN:
      return state.set('canAccessScheduleScreen', fromJS(action?.canAccess));
    default:
      return state;
  }
};

export default dailyAgendaReducer;
