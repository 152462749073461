import React from 'react';
import defaultRoutes from 'utils/defaultRoutes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserType, selectUserLocation, selectUser } from 'store/selectors/user.selectors';
import HeaderWithNavigation from 'components/HeaderWithNavigation';
import DailyAgenda from './DailyAgendaDashboard';

const DailyAgendaWrap = () => {
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const userDetails = useSelector(selectUser);

  return (
    <HeaderWithNavigation hideZone hideSubZone hideCluster hideLocation routes={defaultRoutes(t, userType, location, userDetails)}>
      <DailyAgenda />
    </HeaderWithNavigation>
  );
};

export default DailyAgendaWrap;
