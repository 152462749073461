// DO NOT MODIFY THIS FILE
import { UserTypes } from 'types/enums';
import { Logistics_User_Roles, Supply_User_Roles, User_Features, General_User_Roles } from 'generated/graphql';
import { roadSafetyResourcesPosition } from 'features/RoadSafety/Resources/Resources';

const dailyAgendaPosition = [
  'AUDITOR DPO',
  'MANAGER DPO',
  'GERENTE DE OPERACIONES',
  'JEFE DE OPERACIONES',
  'SUPERVISOR DE DISTRIBUCIÓN',
  'SUPERVISOR DE ALMACÉN',
  'JEFE DE SEGURIDAD',
  'JEFE DE FLOTA',
];

export function userIsBreweryPlantManager(
  userRole: Supply_User_Roles,
  userType?: UserTypes,
) {
  return (userRole && [
    Supply_User_Roles.PlantManager,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.BreweryViewer,
      UserTypes.BreweryPlanner,
      UserTypes.BreweryViewerManager,
    ].includes(userType));
}

export function userIsSafetyManagerEnvironmentManager(userRole: Supply_User_Roles) {
  return [
    Supply_User_Roles.SafetyManager,
    Supply_User_Roles.SafetyEnvironmentManager,
    Supply_User_Roles.EnvironmentManager,
  ].includes(userRole);
}

export function userIsSafetyManager(userRole: Supply_User_Roles) {
  return [
    Supply_User_Roles.SafetyManager,
  ].includes(userRole);
}

export function userIsLineManager(userRole: Supply_User_Roles) {
  return [
    Supply_User_Roles.LineManagerSafetyEnvironment,
    Supply_User_Roles.LineManagerSafety,
    Supply_User_Roles.LineManagerEnvironment,
  ].includes(userRole);
}

export function userIsAssigner(userRole: Supply_User_Roles, userType?: UserTypes) {
  return (userRole && [
    Supply_User_Roles.AssignerSafetyEnvironment,
    Supply_User_Roles.AssignerSafety,
    Supply_User_Roles.AssignerEnvironment,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.SubzoneGeneralManagerSupply,
    ].includes(userType));
}

export function userIsExecutor(userRole: Supply_User_Roles, userType?: UserTypes) {
  return (userRole && [
    Supply_User_Roles.EjecutorSafetyEnvironment,
    Supply_User_Roles.EjecutorSafety,
    Supply_User_Roles.EjecutorEnvironment,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.DistributionCenterManagerSupply,
    ].includes(userType));
}

export function userIsReporterOnlySupply(userRole: Supply_User_Roles, userType?: UserTypes) {
  return (userRole && [
    Supply_User_Roles.ReporterOnly,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.ReporterOnlySupply,
    ].includes(userType));
}

export function userIsCorporateSupply(userRole: Supply_User_Roles, userType?: UserTypes) {
  return (userRole && [
    Supply_User_Roles.CorporateSupply,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.CorporateSupply,
    ].includes(userType));
}

export function userIsEnvironment(userRole: Supply_User_Roles) {
  return [
    Supply_User_Roles.EnvironmentManager,
    Supply_User_Roles.LineManagerEnvironment,
    Supply_User_Roles.AssignerEnvironment,
    Supply_User_Roles.EjecutorEnvironment,
    Supply_User_Roles.SafetyEnvironmentManager,
  ].includes(userRole);
}

export function userIsSafety(userRole: Supply_User_Roles) {
  return [
    Supply_User_Roles.SafetyManager,
    Supply_User_Roles.LineManagerSafety,
    Supply_User_Roles.AssignerEnvironment,
    Supply_User_Roles.EjecutorSafety,
    Supply_User_Roles.SafetyEnvironmentManager,
  ].includes(userRole);
}

export function userIsSafetyEnvironment(userRole: Supply_User_Roles) {
  return [
    Supply_User_Roles.LineManagerSafetyEnvironment,
    Supply_User_Roles.AssignerSafetyEnvironment,
    Supply_User_Roles.EjecutorSafetyEnvironment,
    Supply_User_Roles.SafetyEnvironmentManager,
  ].includes(userRole);
}

export function userIsDoctorReporterOnlyDPO(userRole: Logistics_User_Roles, userType: UserTypes) {
  return (userRole && [
    Logistics_User_Roles.DoctorReporterOnlyDpo,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.DoctorReporterOnly,
    ].includes(userType));
}

export function userIsDoctorReporterOnlySupply(userRole: Supply_User_Roles, userType: UserTypes) {
  return (userRole && [
    Supply_User_Roles.DoctorReporterOnlySupply,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.DoctorReporterOnlySupply,
    ].includes(userType));
}

export function userIsSupply(userRole: Supply_User_Roles, userType?: UserTypes) {
  return (userRole && [
    Supply_User_Roles.EjecutorContratistaPdt,
    Supply_User_Roles.KioskUser,
  ].includes(userRole))
    || userIsSafetyManagerEnvironmentManager(userRole as Supply_User_Roles)
    || userIsLineManager(userRole as Supply_User_Roles)
    || userIsAssigner(userRole as Supply_User_Roles, userType)
    || userIsExecutor(userRole as Supply_User_Roles, userType)
    || userIsBreweryPlantManager(userRole as Supply_User_Roles, userType)
    || userIsReporterOnlySupply(userRole as Supply_User_Roles, userType)
    || userIsCorporateSupply(userRole as Supply_User_Roles, userType)
    || (userType && !userRole && [
      UserTypes.DoctorReporterOnlySupply,
    ].includes(userType));
}

export function userIsLogistics(userRole: Logistics_User_Roles,
  userType?: UserTypes) {
  return (userRole && [
    Logistics_User_Roles.ReporterOnlyDpo,
    Logistics_User_Roles.DistributionCenterManagerDpo,
    Logistics_User_Roles.SubzoneGeneralManagerDpo,
    Logistics_User_Roles.CorporateDpo,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.CorporateDPO,
      UserTypes.DistributionCenterManagerDPO,
      UserTypes.SubzoneGeneralManagerDPO,
    ].includes(userType));
}

export function userIsCorporate(
  userRole: Logistics_User_Roles | Supply_User_Roles,
  userType?: UserTypes,
) {
  return (userRole && [
    Logistics_User_Roles.CorporateDpo,
    Supply_User_Roles.CorporateSupply,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.CorporateDPO,
      UserTypes.CorporateSupply,
    ].includes(userType));
}

export function userIsManagerDPO(
  userRole: Logistics_User_Roles,
  userType?: UserTypes,
) {
  return (userRole && [
    Logistics_User_Roles.DistributionCenterManagerDpo,
    Logistics_User_Roles.SubzoneGeneralManagerDpo,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.DistributionCenterManagerDPO,
      UserTypes.SubzoneGeneralManagerDPO,
    ].includes(userType));
}

export function userIsManagerCorporateDPO(
  userRole: Logistics_User_Roles,
  userType?: UserTypes,
) {
  return (userRole && [
    Logistics_User_Roles.DistributionCenterManagerDpo,
    Logistics_User_Roles.SubzoneGeneralManagerDpo,
    Logistics_User_Roles.CorporateDpo,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.DistributionCenterManagerDPO,
      UserTypes.SubzoneGeneralManagerDPO,
      UserTypes.CorporateDPO,
    ].includes(userType));
}

export function userIsReporterOnlyDPO(userRole: Logistics_User_Roles, userType?: UserTypes) {
  return (userRole && [
    Logistics_User_Roles.ReporterOnlyDpo,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.ReporterOnlyDPO,
    ].includes(userType));
}

export function userIsCertificatesOnly(
  userRole: General_User_Roles,
  userType?: UserTypes,
) {
  return (userRole && [
    General_User_Roles.CertificatesOnly,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.CertificatesOnly,
    ].includes(userType));
}

export function canUserBlockUnblock(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.BlockUnblockUsers;
  }) ?? false;
}

export function canUserDownload(
  userRole: Logistics_User_Roles,
  userType?: UserTypes,
) {
  return (userRole && [
    Logistics_User_Roles.CorporateDpo,
    Logistics_User_Roles.SubzoneGeneralManagerDpo,
    Logistics_User_Roles.DistributionCenterManagerDpo,
  ].includes(userRole))
    || (userType && !userRole && [
      UserTypes.CorporateDPO,
      UserTypes.DistributionCenterManagerDPO,
      UserTypes.SubzoneGeneralManagerDPO,
    ].includes(userType));
}

export function userHasWebAccess(
  userRole: Supply_User_Roles | Logistics_User_Roles | General_User_Roles,
  userFeatures: any,
  userType?: UserTypes,
  userData?: any,
) {
  return ((userRole && [
    Logistics_User_Roles.DistributionCenterManagerDpo,
    Logistics_User_Roles.SubzoneGeneralManagerDpo,
    Logistics_User_Roles.CorporateDpo,
    General_User_Roles.Doctor,
  ].includes(userRole as Logistics_User_Roles | General_User_Roles))
    || userIsLineManager(userRole as Supply_User_Roles)
    || userIsAssigner(userRole as Supply_User_Roles, userType)
    || userIsSafetyManagerEnvironmentManager(userRole as Supply_User_Roles)
    || userIsBreweryPlantManager(userRole as Supply_User_Roles, userType)
    || userFeatures?.map((userFeature: any) => [
      User_Features.SafetyWebAccess,
    ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true))
    && !(
      userIsReporterOnlyDPO(userRole as Logistics_User_Roles, userType)
      && [
        'PY',
        'AR',
      ].includes(userData?.distributionCenter?.country)
    );
}

export function userOnlyHasAccessToReportSIOs(
  userFeatures?: any,
  userData?: any,
) {
  return (userFeatures?.map((userFeature: any) => [
    User_Features.SioOnlyReportsWeb,
  ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true))
    && ![
      'PY',
      'AR',
    ].includes(userData?.distributionCenter?.country);
}

export function userCanAddNewUserPin(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CreateUserPin;
  }) ?? false;
}

export function userCanReportSIOs(userFeatures: any, userData?: any) {
  const hasWebReportSios = userFeatures?.some((userFeature: any) => [User_Features.WebReportSios].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug),
  );

  if (hasWebReportSios) {
    if (userData?.distributionCenter?.type === 'DISTRIBUTION_CENTER') {
      return !['PY', 'AR'].includes(userData?.distributionCenter?.country)
        && ['MX'].includes(userData?.distributionCenter?.country);
    }
    return true;
  }
  return false;
}

export function userCanDownloadExcelSupply(
  userRole: Supply_User_Roles,
  userFeatures: any,
) {
  return (userRole && [
    Supply_User_Roles.WebOnly,
  ].includes(userRole))
    || userIsLineManager(userRole as Supply_User_Roles)
    || userIsAssigner(userRole as Supply_User_Roles)
    || userIsSafetyManagerEnvironmentManager(userRole as Supply_User_Roles)
    || userIsExecutor(userRole as Supply_User_Roles)
    || userFeatures?.map((userFeature: any) => [
      User_Features.SafetyWebAccess,
    ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true);
}

export function hasAccessToAL(userRole: Logistics_User_Roles,
  userFeatures: any,
  userType?: UserTypes) {
  return userIsManagerCorporateDPO(userRole, userType)
    || userFeatures?.map((userFeature: any) => [
      User_Features.ActionLogsWeb,
    ].includes(userFeature?.size ? userFeature?.get('slug') : userFeature?.slug)).includes(true);
}

export function canUserAccessPDT(
  userRole: Supply_User_Roles,
  userFeatures: any[],
  userType: UserTypes,
): boolean {
  return (
    (
      userIsSupply(userRole, userType)
      && !userIsBreweryPlantManager(userRole, userType)
      && !userIsCorporateSupply(userRole, userType)
    )
    || userFeatures?.map((userFeature: any) => [User_Features.PdtApp].includes(
      userFeature?.size ? userFeature?.get('slug') : userFeature?.slug,
    ),
    ).includes(true)
  );
}

export function canUserLoginWeb(
  userFeatures: any[],
  userData: any,
) {
  return (userFeatures?.map((userFeature: any) => [
    User_Features.SafetyWebAccess,
    User_Features.WebReportSios,
    User_Features.SioOnlyReportsWeb,
  ].includes(userFeature?.slug as User_Features)).includes(true)
    && !([
      'PY',
      'AR',
    ].includes(userData?.distributionCenter?.country)
    )
  );
}

export function userIsWebOnly(userRole: Supply_User_Roles) {
  return [
    Supply_User_Roles.WebOnly,
    Supply_User_Roles.KioskUser,
  ].includes(userRole);
}

export function userOnlyHasAccessToSearchUserScreen(userType?: UserTypes) {
  return (userType && [
    UserTypes.LocationSecurityDoctor,
    UserTypes.LocationManager,
    UserTypes.LocationSecurity,
  ].includes(userType));
}

// export function canUserDPODelivery(userFeatures: any) {
//   return userFeatures?.some((userFeature: any) => {
//     const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
//     return slug === User_Features.OperatorDpoAcademyDelivery;
//   }) ?? false;
// }
// export function canUserDPOWarehouse(userFeatures: any) {
//   return userFeatures?.some((userFeature: any) => {
//     const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
//     return slug === User_Features.OperatorDpoAcademyWarehouse;
//   }) ?? false;
// }
// export function canUserDPOSupervisor(userFeatures: any) {
//   return userFeatures?.some((userFeature: any) => {
//     const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
//     return slug === User_Features.SupervisorDpoAcademy;
//   }) ?? false;
// }

export function canUserCloseUnassignedIncidentSupply(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CloseUnassignedIncidentSupply;
  }) ?? false;
}

export function canCountIncidentsByLocation(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CountIncidentsByLocation;
  }) ?? false;
}

export function canCountIncidentsByDepartment(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CountIncidentsByDepartment;
  }) ?? false;
}

export function canCountIncidentsByArea(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CountIncidentsByArea;
  }) ?? false;
}

export function canCountIncidentsBySubArea(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CountIncidentsBySubarea;
  }) ?? false;
}

export function canGetIncidentsByDepartment(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetIncidentsByDepartment;
  }) ?? false;
}

export function canGetIncidentsByArea(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetIncidentsByArea;
  }) ?? false;
}

export function userCanGetSIFNotificationByLocation(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetSifNotificationByLocation;
  }) ?? false;
}

export function userCanGetSIFNotificationByDepartment(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetSifNotificationByDepartment;
  }) ?? false;
}

export function userCanGetPriorityNotificationByLocation(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetPriorityNotificationByLocation;
  }) ?? false;
}

export function userCanGetPriorityNotificationByDepartment(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetPriorityNotificationByDepartment;
  }) ?? false;
}

export function canUserSaveDepartments(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.SaveDepartments;
  }) ?? false;
}

export function canUserSaveAreas(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.SaveAreas;
  }) ?? false;
}

export function canUserSaveSubAreas(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.SaveSubAreas;
  }) ?? false;
}

export function canUserGetDepartments(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetUserDepartments;
  }) ?? false;
}

export function canUserGetAreas(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetUserAreas;
  }) ?? false;
}

export function canUserGetSubAreas(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.GetUserSubAreas;
  }) ?? false;
}

export function canUserPostponeIncidents(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.PostponeIncident;
  }) ?? false;
}

export function canUserAssignSios(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AssignSios;
  }) ?? false;
}

export function canUserAssignAmbientalRisks(userFeatures?: any): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AssignSiosAr;
  }) ?? false;
}

export function canUserDownloadHC(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.DownloadHc;
  }) ?? false;
}

export function canUserDownloadAccessHC(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.DownloadGeneralHc;
  }) ?? false;
}

export function canUserDownloadSKAPHC(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.DownloadSkapHc;
  }) ?? false;
}

export function canUserAssignSIOsExecutors(
  userFeatures: User_Features[],
): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AssignSios;
  }) ?? false;
}

export function canUserEditSIOsSIF(
  userFeatures: User_Features[],
): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.EditSiosSif;
  }) ?? false;
}

export function canUserCloseSIOS(
  userFeatures: User_Features[],
): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CloseSios;
  }) ?? false;
}

export function canUserReturnSIOS(
  userFeatures: any,
): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.ReturnSios;
  }) ?? false;
}

export function canUserEditSIOS(
  userFeatures: any,
): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.EditSios;
  }) ?? false;
}

export function canUserReportSIOS(
  userFeatures: User_Features[],
) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.ReportSios;
  }) ?? false;
}

export function canUserAccessAssignSIOsScreen(
  userFeatures: any,
): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AssignSiosScreenMobile;
  }) ?? false;
}

export function canUserAccessAnalytics3rs(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsThreeRs;
  }) ?? false;
}

export function canUserBeLimitedByHourSios(userFeatures?: User_Features[]): boolean {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.SiosLimitPerHour;
  }) ?? false;
}

export function canUserDeleteUsers(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.DeleteUsers;
  }) ?? false;
}

// feature flag SIOS web version flow only for pilot locations
export function isLocationFromSiosWebPilot(locationSelected: any) {
  const countryCode = typeof locationSelected !== 'string' ? locationSelected?.get('code') : locationSelected;
  const isValidCountryCode = ['EC', 'PE'].includes(countryCode);

  return isValidCountryCode;
}

export function canUserEditOperations(user: any) {
  return user?.features?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.EditOperations;
  }) ?? false;
}

export function canUserAccessOverview(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Overview;
  }) ?? false;
}
export function canUserAccessAnalytics(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Analytics;
  }) ?? false;
}
export function canUserAccessSearchUsers(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.SearchUsers;
  }) ?? false;
}
export function canUserAccessLocations(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Locations;
  }) ?? false;
}
export function canUserAccessPdts(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Pdts;
  }) ?? false;
}
export function canUserAccessDPOAcademy(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.DpoAcademy;
  }) ?? false;
}
export function canUserAccessBoards(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Boards;
  }) ?? false;
}

export function canUserAccessOwds(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Owd;
  }) ?? false;
}

export function canUserAccessRoutines(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsRoutines;
  }) ?? false;
}

export function canUserAccessACIs(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Acis;
  }) ?? false;
}

export function canUserAccessAnalyticsEnvironmentalRisks(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsEnvironmentalRisks;
  }) ?? false;
}

export function canUserAccessSefetyCertificatesT2(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsSefetyCertificatesT2;
  }) ?? false;
}

export function canUserAccessAnalyticsRoutines(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsRoutines;
  }) ?? false;
}

export function canUserAccessAnalyticsSkap(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsSkap;
  }) ?? false;
}

export function canUserAccessCashless(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsCashless;
  }) ?? false;
}

export function canUserAccessAnalytics5s(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsFiveS;
  }) ?? false;
}

export function canUserAccessPowerBiSiosT2(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsPowerBiSiosT2;
  }) ?? false;
}

export function canUserAccessSifRoute(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsSifRoute;
  }) ?? false;
}

export function canUserAccessComplianceSif(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsComplianceSif;
  }) ?? false;
}

export function canUserAccessAccidentRate(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsAccidents;
  }) ?? false;
}

export function canUserAccessStatisticsSif(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsStatisticsSif;
  }) ?? false;
}

export function canUserAccessConsequences(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsConsequences;
  }) ?? false;
}

export function canUserAccessSafetyTraining(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsSafetyTraining;
  }) ?? false;
}

export function canUserAccessCapex(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsCapex;
  }) ?? false;
}

export function canUserAccessGKPI(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsGkpi;
  }) ?? false;
}

export function canUserAccessffectivenessOfRoutines(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsEffectivenessOfRoutines;
  }) ?? false;
}

export function canUserAccessPreventionOfViolence(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsPreventionOfViolence;
  }) ?? false;
}

export function canUserAccessEffectivenessOfWorkstations(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsEffectivenessOfWorkstations;
  }) ?? false;
}

export function canUserAccessEffectivenessOfRoutines(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsEffectivenessOfRoutines;
  }) ?? false;
}

export function canUserAccessDMS(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsDms;
  }) ?? false;
}

export function canUserAccessAnalytics3Rs(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.Analytics_3rs;
  }) ?? false;
}

export function canUserAccessUsabilitydDPO(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsUsabilityDpo;
  }) ?? false;
}

export function canUserAccessAnalyticsOWDs(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsOwds;
  }) ?? false;
}

export function canUserAccessAnalyticsAcis(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.AnalyticsAcis;
  }) ?? false;
}

export function canUserAccessSafetyAcademySideBar(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.SafetyAcademySidebar;
  }) ?? false;
}

export function canUserAccessRoadSafetyResourcesSideBar(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.ResourcesRoadSafetyWeb;
  }) ?? false;
}

export function canUserAccessRoadSafetySideBar(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.RoadSafetyWeb;
  }) ?? false;
}

export function canUserAccessCriticalRoutesSideBar(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CriticalRoutesRoadSafetyWeb;
  }) ?? false;
}

export function canUserAccessRoadSafetyResources(
  userRole: Logistics_User_Roles,
) {
  return (userRole && [
    Logistics_User_Roles.DistributionCenterManagerDpo,
    Logistics_User_Roles.SubzoneGeneralManagerDpo,
    Logistics_User_Roles.CorporateDpo,
  ].includes(userRole));
}

export function canUserAccessRoadSafety(userLocationId: number) {
  return [229, 348, 417, 431, 441, 485, 491, 667]?.includes(userLocationId);
}
export function userCanAddNewUserByEmployeeNumber(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CreateUserByEmployeeNumber;
  }) ?? false;
}

export function canUserAccessRoadSafetyCriticalRoutesActions(
  userFeatures: any,
  userPosition: string,
) {
  const canAccessViaFeatureFlag = userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.CriticalRoutesRoadSafetyWeb;
  }) ?? false;
  const canAccessViaPosition = roadSafetyResourcesPosition.includes(userPosition);

  if (canAccessViaFeatureFlag && canAccessViaPosition) {
    return true;
  }

  return false;
}

export function canUserAccessRoadSafetyDriverResourceActions(
  userFeatures: any,
  userPosition: string,
) {
  const canAccessViaFeatureFlag = userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.DriversRoadSafetyWeb;
  }) ?? false;
  const canAccessViaPosition = roadSafetyResourcesPosition.includes(userPosition);

  if (canAccessViaFeatureFlag && canAccessViaPosition) {
    return true;
  }

  return false;
}

export function canUserAccessRoadSafetyDriverSideBar(userFeatures: any) {
  return userFeatures?.some((userFeature: any) => {
    const slug = userFeature?.size ? userFeature.get('slug') : userFeature?.slug;
    return slug === User_Features.DriversRoadSafetyWeb;
  }) ?? false;
}

export function canAccessDailyAgenda(userPosition: string) {
  return dailyAgendaPosition.includes(userPosition?.toUpperCase());
}

