import React, { useEffect, useMemo, useState } from 'react';
import WebsiteVersion from 'components/WebsiteVersion';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/system/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import { setCanAccessScheduleScreen } from 'store/actions/dailyAgenda.actions';
import { RoutesForNavigationDrawerWithVerticalMenu } from '../../../types/interfaces';
import * as styles from '../NavigationDrawerStyles';
import { useDispatch } from 'react-redux';

type TCollapsibleRoute = {
  routeName: string;
  isOpen: boolean;
  parentRouteName: string;
};

export type Props = {
  routes: RoutesForNavigationDrawerWithVerticalMenu[];
  openDrawer: boolean;
};

function NavigationDrawerWithVerticalMenu({ routes, openDrawer }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [collapsibleRoutes, setCollapsibleRoutes] = useState<TCollapsibleRoute[]>([]);
  const [currentActiveRoute, setCurrentActiveRoute] = useState<string>(location.pathname || '');

  function handleNavigation(routeName: string) {
    navigate(routeName);
    setCurrentActiveRoute(routeName);
  }

  function handleCollapse(toOpenRouteConfig: TCollapsibleRoute, parentRouteName: string) {
    const [{ collapseList }] = routes.filter(route => route.routeName === parentRouteName);
    const collapsibleRoutesValue: TCollapsibleRoute[] = [];
    collapseList?.forEach(
      route => collapsibleRoutesValue.push(
        { ...route, parentRouteName, isOpen: !toOpenRouteConfig?.isOpen },
      ),
    );
    setCollapsibleRoutes(collapsibleRoutesValue);
  }

  function handleNavItemClick(routeName: string, isCollapse: boolean, toOpenRouteConfig: TCollapsibleRoute) {
    if (isCollapse) {
      handleCollapse(toOpenRouteConfig, routeName);
    } else {
      handleNavigation(routeName);
    }
  }

  const selectedRoute = useMemo(() => location.pathname, [location.pathname]);

  useEffect(() => {
    if (selectedRoute !== '/viewDailyAgenda' && selectedRoute !== '/editDailyAgenda') {
      dispatch(setCanAccessScheduleScreen(false));
    }
    const routesWithCollapseList = routes
      .filter(route => !!route.collapseList?.length)[0];

    setCollapsibleRoutes((routesWithCollapseList?.collapseList || [])
      .map(({ routeName }) => ({
        routeName,
        isOpen: selectedRoute === routeName,
        parentRouteName: routesWithCollapseList.routeName,
      })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute, routes]);

  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <Drawer
        variant="permanent"
        anchor="left"
        open={openDrawer}
        PaperProps={{ sx: styles.drawerPaper }}
        // PaperAnchorLeft={styles.DrawerPaperAnchorLeft}
      >
        <List component="nav" disablePadding>
          {routes.map((route, key) => {
            const [toOpenCheck] = collapsibleRoutes.filter(collapse => collapse.parentRouteName === route.routeName);

            return (
              <React.Fragment key={key}>
                <ListItemButton
                  sx={styles.root}
                  selected={currentActiveRoute === route.routeName}
                  onClick={() => handleNavItemClick(route.routeName, !!route.collapseList?.length, toOpenCheck)}
                  style={(toOpenCheck?.isOpen && route.collapseList?.map(collapseList => collapseList.routeName).includes(currentActiveRoute)) ? styles.highlightBackground : {}}
                >
                  <img
                    src={(currentActiveRoute === route.routeName
                      || route.collapseList?.map(collapseList => collapseList.routeName).includes(currentActiveRoute))
                      ? route.listItemIconActive : route.listItemIcon}
                    alt={route.routeName}
                    style={styles.listIcon}
                  />
                  <ListItemText
                    primary={route.listItemText}
                    sx={currentActiveRoute === route.routeName ? styles.primary : null}
                  />
                  {!!route.collapseList?.length && (toOpenCheck?.isOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                {!!route.collapseList?.length && route.collapseList && toOpenCheck?.isOpen && (
                  <Collapse
                    in={toOpenCheck.isOpen}
                    timeout="auto"
                    unmountOnExit
                    sx={styles.nested}
                    style={{ minHeight: 'none' }}
                  >
                    <List component="div" disablePadding>
                      {route.collapseList.map((collapseList, keyCollapse) => (
                        <ListItemButton
                          key={keyCollapse.toString()}
                          sx={styles.rootInsideCollapse}
                          selected={currentActiveRoute === collapseList.routeName}
                          onClick={() => handleNavigation(collapseList.routeName)}
                          style={collapseList?.listItemIconActive ? { backgroundColor: 'unset' } : {}}
                        >
                          {(collapseList?.listItemIconActive && currentActiveRoute === collapseList.routeName) ? (
                            <img
                              src={currentActiveRoute === collapseList.routeName ? collapseList?.listItemIconActive : collapseList?.listItemIcon}
                              alt={route.routeName}
                              style={{
                                position: 'absolute',
                                left: '-2px',
                              }}
                            />
                          ) : null}
                          <ListItemText
                            style={{ width: '100px' }}
                            primary={collapseList.listItemText}
                            sx={currentActiveRoute === collapseList.routeName ? (collapseList?.listItemIconActive ? styles.primaryV1 : styles.primary) : null}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </Drawer>
      <Box sx={styles.version}>
        <WebsiteVersion />
      </Box>
    </Box>
  );
}

export default NavigationDrawerWithVerticalMenu;
