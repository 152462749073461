import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { useDispatch, useSelector } from 'react-redux';
import './DailyAgendaDashboard.scss';
import CircularProgress from '@mui/material/CircularProgress';
import ImageLinks from 'utils/ImageLinks';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import ExpandableTable from 'components/ExpandableTable';
import { useNavigate } from 'react-router-dom';
import { setCanAccessScheduleScreen } from 'store/actions/dailyAgenda.actions';
import { selectUser } from 'store/selectors/user.selectors';

// Remove below imports
import useGetAnalyticsTraining from 'features/analyticsWrapper/AnalyticsTraining/hooks/useGetAnalyticsTraining';
import { trainignsMonthSelectordropDownItems } from 'utils/dateHelpers';
import Modal from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import axios from 'axios';
import { selectLocationsToAccess, selectSelectedLocation } from 'store/selectors/globalFilters.selectors';
import { selectGlobalFilters, selectSelectedBusinessUnit } from 'store/selectors/globalFilters.selectors';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import { capitalizeFirst } from 'utils/stringHelpers';

const dailyAgendaPosition = [
  'AUDITOR DPO',
  'MANAGER DPO',
];

const monthArr = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
];
export enum DropDownEnums {
  month = 'month',
  year = 'year',
}

const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
  slug: monthSlug,
  label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
  months: [monthSlug],
  lastMonth: [monthSlug === 'enero' ? 'diciembre' : monthArr[monthIndex - 1]],
}));

const getCurrentMonth = () => {
  const localDate: Date = new Date();
  const monthIndexToGet: number = localDate.getUTCMonth();

  return monthOptions[monthIndexToGet];
};

const { CancelToken } = axios;

const buList = [
  {
    buId: 1,
    label: 'Mexico',
    slug: 'mexico',
  },
  {
    buId: 2,
    label: 'CAC',
    slug: 'cac',
  },
];

const DailyAgenda = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.get('user'))?.toJS();
  const userDetails = useSelector(selectUser);

  const canAccessScheduleScreen = dailyAgendaPosition.includes(userDetails?.get('position')?.toUpperCase());
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);
  const [selectedBu, setSelectedBu] = useState(buList[0]);
  const [isBuDropDownOpen, setIsBuDropDownOpen] = useState<boolean>(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState<DropDownEnums | undefined>(undefined);

  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [dropDownOpenMonth, setDropDownOpenMonth] = useState(false);

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };
  const yearOptions = getLatestYearsAndCurrentYear();
  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [DAData, setDAData] = useState();
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit);
  const globalFilters = useSelector(selectGlobalFilters);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const url = `/api/v1/da-get-task-per-bu?buId=${selectedBusinessUnit.id}`;
        const url = '/api/v1/da-personal-tasks';
        const body = {
          level: 'bu',
          id: selectedBusinessUnit?.id,
        };
        const res = await request.post(url, body);
        setDAData(res.response);
      } catch (error) {
        console.error('Error fetching operator positions:', error);
      }
    };
    if (selectedBusinessUnit?.id) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessUnit]);

  // const getLatestYearsAndCurrentYear = () => {
  //   const localDate: Date = new Date();
  //   const baseYear: number = 2021;
  //   const currentYear: number = localDate.getUTCFullYear();
  //   const years: any[] = [];

  //   for (let i = baseYear; i <= currentYear; i++) {
  //     const currentYearToString: string = i.toString();

  //     years.push({
  //       label: currentYearToString,
  //       slug: currentYearToString,
  //       year: i,
  //     });
  //   }

  //   return years.reverse();
  // };
  // const yearOptions = getLatestYearsAndCurrentYear();
  // const getCurrentYear = () => {
  //   const localDate: Date = new Date();
  //   const currentYear: number = localDate.getUTCFullYear();
  //   const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
  //   return yearDataToReturn ?? yearOptions[0];
  // };
  // const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  // const [loading, setLoading] = useState<boolean>(false);

  // just for testing, delete
  const locations = useSelector(selectLocationsToAccess);

  const onPressDownload = () => {
    setShowDownloadModal(true);
  };

  const onPressSchedule = () => {
    dispatch(setCanAccessScheduleScreen(true));
    navigate('/viewDailyAgenda');
  };

  // Replace with the DA api call.
  const dropDownItems = trainignsMonthSelectordropDownItems(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);

  const usersTraining = useGetAnalyticsTraining({
    selectedTimeRange: selectedDropDownItem,
  });
  // const usersTraining = useGetAnalyticsTraining({
  //   selectedTimeRange: selectedDropDownItem,
  // });

  // ------------------------------

  const rowElements = (DAMap: any) => {
    return ({
      activities: DAMap.name || DAMap.userName || DAMap.taskSlug,
      frequency: DAMap.frequency,
      week1: 0,
      week2: 0,
      week3: 0,
      week4: 0,
      week5: 0,
      percentage: 0,
    });
  };

  const data = useMemo(() => {
    if (!DAData) return;

    const formatPositionName = (name: string) => name.replace(/_/g, ' ');

    const mapDistributionCenters = (distributionCenters: unknown[]) =>
      distributionCenters.map((dc: any) => ({
        ...rowElements(dc),
        subRows: Object.keys(dc.positions[0] || {}).map((positionName: string) => {
          const formattedName = formatPositionName(positionName);
          const positionsArray = Object.values(dc.positions[0]).flat();

          return {
            ...rowElements({ name: formattedName }),
            subRows: positionsArray
              .filter((userp: any) => userp.daPositionName === formattedName)
              .map((userp: any) => ({
                ...rowElements(userp),
              })),
          };
        }),
      }));

    const mapSubzones = (subzones: unknown[]) =>
      subzones.map((subzone: any) => ({
        ...rowElements(subzone),
        subRows: mapDistributionCenters(subzone.distributionCenters),
      }));

    const mapZones = (zones: unknown[]) =>
      zones.map((zone: any) => ({
        ...rowElements(zone),
        subRows: mapSubzones(zone.subzones),
      }));

    return [DAData].map((bu: any) => ({
      ...rowElements(bu),
      subRows: mapZones(bu.zones),
    }));
  }, [DAData]);


  const columns = useMemo(
    () => [
      {
        Header: t(TRANSLATIONS.ACTIVITIES),
        accessor: 'activities',
        Cell: (rowData: any) => {
          if (rowData?.row?.depth === 5) {
            return (
              <div>
                <div style={{ textAlign: 'left' }}>{rowData?.row?.original?.activities ?? ''}</div>
                {!!rowData?.row?.original?.position && (
                  <div style={{ textAlign: 'left', fontSize: 10, fontWeight: '400', color: '#B5B5B5' }}>{rowData?.row?.original?.position}</div>
                )}
              </div>
            );
          }
          return rowData?.value ? <div style={{ textAlign: 'left' }}>{rowData?.value}</div> : '';
        },
        getProps: (state: any) => {
          let paddingLeft;
          if (state.row.depth === 1) paddingLeft = 20;
          if (state.row.depth === 2) paddingLeft = 30;
          if (state.row.depth === 3) paddingLeft = 40;
          if (state.row.depth === 4) paddingLeft = 50;
          if (state.row.depth === 5) paddingLeft = 50;
          if (state.row.depth === 6) paddingLeft = 50;
          if (state.row.depth) {
            return {
              style: {
                fontWeight: 'bold',
                paddingLeft: paddingLeft,
              },
            };
          }
          return {};
        },
      },
      {
        Header: '',
        accessor: 'frequency',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 1`,
        accessor: 'week1',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 2`,
        accessor: 'week2',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 3`,
        accessor: 'week3',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 4`,
        accessor: 'week4',
      },
      {
        Header: `${t(TRANSLATIONS.WEEK)} 5`,
        accessor: 'week5',
      },
      {
        Header: `${t(TRANSLATIONS.TOTAL_MONTH)}`,
        accessor: 'percentage',
        getProps: () => {
          const color = () => {
            // eslint-disable-next-line radix
            // const percentage = Math.round(parseInt(state.value.split('%')[0]));
            const percentage = 0;
            if (percentage <= 70) {
              return '#C00000';
            }
            if (percentage > 70 && percentage < 90) {
              return '#404040';
            }
            return '#70AD47';
          };
          return {
            style: {
              color: color(),
            },
          };
        },
      },
    ],
    [t],
  ) as any;

  const handleDropMonthChange = ({ key }: { key: any }) => {
    setSelectedMonth(monthOptions.find(
      (item: { slug: any }) => item.slug === key,
    ) || monthOptions[0]);
    setDropDownOpenMonth(false);
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const downloadDAExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const url = `/api/v1/pdt/excel?locationIds=${JSON.stringify([locations])}`;
        // const url = `/api/v1/da/excel?buId=${JSON.stringify([selectedBu.id])}`;
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locations],
  );

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const handleBuChange = ({ key }: { key: any }) => {
    setSelectedBu(buList.find(
      (item: { slug: any }) => item.slug === key,
    ) || buList[0]);
    setIsBuDropDownOpen(false);
  };

  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: DropDownEnums) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };
  const isDCSelected = useSelector(selectSelectedLocation);
  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      if (isDCSelected?.locationSlug) {
        return [
          { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
          ...possibleMonths,
        ];
      }
      return possibleMonths;
    }
    if (isDCSelected?.locationSlug) {
      return [
        { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
        ...monthOptions,
      ];
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear, isDCSelected]);


  const ResourcesEmptyPage = () => {
    return (
      globalFilters?.size ? (
        <div className="roadSafetyResources__selectDc">
          <img src={ImageLinks.selectDc} alt="Select Location" />
          <div className="roadSafetyResources__selectDc__info">
            <p className="roadSafetyResources__selectDc__info__title">{t(TRANSLATIONS.SELECT_A_BUSINESS_UNIT)}</p>
            <p className="roadSafetyResources__selectDc__info__subTitle">{t(TRANSLATIONS.IN_ORDER_TO_ACCESS_DAILY_AGENDA_IT_IS_NECESSARY_TO_SELECT_A_BUSINESS_UNIT_USING_THE_TOP_BAR)}</p>
          </div>
        </div>
      ) : (
        <LoadingSpinner height={30} style={{ position: 'fixed', top: '50%', left: '50%' }} />
      )
    );
  };

  return (
    <div className="dailyAgenda">
      <div className="dailyAgenda__header">
        <p className="dailyAgenda__header__title">{t(TRANSLATIONS.DAILY_AGENDA)}</p>
        <div className="dailyAgenda__header__button_container">
          <BasicButton icon={<img src={ImageLinks.downloadFolder} alt="failed request" className='dailyAgenda__header__button_container__downloadIcon' />} text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="dailyAgenda__header__button_container__download_button" onClick={onPressDownload} />
          {canAccessScheduleScreen && (
            <BasicButton text={t(TRANSLATIONS.SCHEDULE)} className="dailyAgenda__header__button_container__schedule_button" onClick={onPressSchedule} />
          )}
        </div>
      </div>
      {usersTraining.loading && (
        <div className="dailyAgenda__loader">
          <CircularProgress />
        </div>
      )}
      {selectedBusinessUnit?.id && data
        ? (
        <>
          <div className="dailyAgenda__userInfo">
            <div className="dailyAgenda__userInfo__profile">
              <img
                src={user?.picture?.length ? user.picture : encodeURI(`https://ui-avatars.com/api/?name=${user?.name}&color=ff0000`)}
                className="dailyAgenda__userInfo__profile__userImage"
                alt="uiAvatarImage"
              />
              <div className="dailyAgenda__userInfo__profile__info">
                <p className='dailyAgenda__userInfo__profile__info__name'>{user?.name}</p>
                <p className="dailyAgenda__userInfo__profile__info__email">{capitalizeFirst(user?.position)} </p>
              </div>
            </div>
            <div className="dailyAgenda__userInfo__progress">
              <div className="dailyAgenda__userInfo__progress__indicator">
                <CircularProgress variant="determinate" value={30} />
                <p className="dailyAgenda__userInfo__progress__indicator__label">50%</p>
              </div>
              <div className="dailyAgenda__userInfo__progress__info">
                <p className="dailyAgenda__userInfo__progress__info__title">50/100 completadas en el trimestre</p>
                <p className="dailyAgenda__userInfo__progress__info__subtitle">Tienes hasta el Lunes 30 de Diciembre</p>
              </div>
            </div>
            <div className="dailyAgenda__userInfo__progress">
              <div className="dailyAgenda__userInfo__progress__indicator">
                <CircularProgress variant="determinate" value={30} />
                <p className="dailyAgenda__userInfo__progress__indicator__label">50%</p>
              </div>
              <div className="dailyAgenda__userInfo__progress__info">
                <p className="dailyAgenda__userInfo__progress__info__title">50/100 completadas en el mes</p>
                <p className="dailyAgenda__userInfo__progress__info__subtitle">Tienes hasta el Lunes 30 de Septiembre</p>
              </div>
            </div>
          </div>
          <div className="dailyAgenda__monthSelector">
            <ChartDropdown
              onChange={handleDropMonthChange}
              selectedDropDownItem={selectedMonth}
              dropDownItems={monthOptions}
              dropDownOpen={dropDownOpenMonth}
              setDropDownOpen={setDropDownOpenMonth}
              labelClassName="label-default"
            />
          </div>
          <ExpandableTable
            removeDepthMargin
            tableClassName="dailyAgenda__table" {...{ columns, data, isExpandable: true }}
            showExpanderAtLast
            openIcon={ImageLinks.chevronUp}
            closeIcon={ImageLinks.chevronDown3}
          />
        </>) :
      <ResourcesEmptyPage />
      }
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
              <div className="downloadMoal__progress_bar">
                <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.SELECT_THE_BU_AND_PERIOD_TO_DOWNLOAD_THE_DATA)}</p>
              <div className="downloadMoal__header">
              <div className="viewDailyAgenda__header__info__content__buSelectorWrap">
                  <p className="viewDailyAgenda__header__info__content__buSelectorWrap__label">{t(TRANSLATIONS.DOWNLOADING_PROGRAMMING_OF)}</p>
                  <div className="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown">
                    <ChartDropdown
                      onChange={handleBuChange}
                      selectedDropDownItem={selectedBu}
                      dropDownItems={buList}
                      dropDownOpen={isBuDropDownOpen}
                      setDropDownOpen={setIsBuDropDownOpen}
                      labelClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__label"
                      dropdownLabelClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__dropdownLabel"
                      customClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__container"
                      dropdownArrowColor="#4472C4"
                      iconClassName="viewDailyAgenda__header__info__content__buSelectorWrap__dropdown__icon" />
                  </div>
                </div>
              </div>
              <div className="downloadMoal__yearFilter">
                <div>
                  <p className="downloadMoal__yearFilter__yearTitle">{t(TRANSLATIONS.YEAR)}</p>
                  <ChartDropdown
                    onChange={handleDropYearChange}
                    selectedDropDownItem={selectedYear}
                    dropDownItems={yearOptions}
                    dropDownOpen={isDropDownOpen === DropDownEnums.year}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, DropDownEnums.year)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
                <div>
                  <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.MONTH)}</p>
                  <ChartDropdown
                    onChange={handleDropMonthChange}
                    selectedDropDownItem={selectedMonth}
                    dropDownItems={validMonthOptions}
                    dropDownOpen={isDropDownOpen === DropDownEnums.month}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, DropDownEnums.month)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
              </div>
            </>
          )}
          {!sentViaEmail ? (
            <div className="downloadMoal__button_container">
              {downloadError ? (
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadDAExcel} />
              ) : (
                <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadDAExcel} />
              )}
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={showProgressBar ? onPressCancelRequest : closeModal} />
            </div>
          ) : (
            <div className="downloadMoal__continue_button_container">
              <BasicButton text={t(TRANSLATIONS.CONTINUE)} className="downloadMoal__continue_button_container__continue_button" onClick={closeModal} />
            </div>
          )}
        </div>
      </Modal>
      )}
    </div>
  );
};

export default DailyAgenda;
